import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Aos from "aos";
import "aos/dist/aos.css";

function AboutScreen() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className=" w-screen min-h-screen ">
      <Header />
      <div
        className=" w-screen bg-cover bg-white bg-opacity-20"
        style={{
          backgroundImage:
            'url("https://img.freepik.com/vecteurs-libre/fond-jaune-conception-lignes-demi-teintes_1017-30148.jpg")',
        }}
      >
        <div className="container mx-auto  w-full">
          <div className="flex flex-col  justify-center items-center mx-3 md:mt-0 mt-24">
            <div className="w-full flex flex-col justify-center items-center my-2">
              <div className="md:h-44 h-16"></div>
              <h1 className="text-[#16163f] text-sm font-bold my-1">
                Labzioui Amine - Web & Mobile Apps Developer.
              </h1>
              <h2 className="text-green-600 md:text-6xl text-4xl font-bold my-2 drop-shadow-xl">
                About Me
              </h2>
              <h2 className="text-sm my-1 bg-[#fff7] rounded px-4 py-2 text-slate-500 ">
                Top-notch web designing & development
              </h2>
              <div className="md:h-24 h-24"></div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="w-sceen bg-cover bg-no-repeat py-5 md:px-5  px-3">
        <div className="container mx-auto w-full  bg-white px-6 my-4">
          {/* top */}
          <div className="flex md:flex-row flex-col-reverse justify-center items-center">
            <div data-aos="fade-right" className="md:w-1/2 w-full px-2 py-1">
              <p className="md:my-2 my-3 text-2xl md:text-left text-center ">
                Hi !! I'm <b>Labzioui Amine</b>
              </p>
              <p className="text-justify my-2">
                {" "}
                a dedicated and innovative web and mobile developer passionate
                about transforming ideas into interactive and efficient digital
                experiences. With a strong foundation in both web and mobile
                technologies, I thrive on creating solutions that seamlessly
                blend functionality with an aesthetically pleasing design.
              </p>
              <p className="text-justify my-2">
                My journey in the world of development started with a curiosity
                about how technology shapes our digital landscape. Over the 3
                years, I have honed my skills in web and mobile development.
                From building responsive websites to crafting cross-platform
                mobile applications, I am committed to staying at the forefront
                of technological advancements.
              </p>
            </div>
            <div data-aos="fade-left" className="md:w-1/2 w-full px-2 py-1">
              <img
                alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                src="https://img.freepik.com/vecteurs-libre/site-web-developpement-web-ingenierie-programmation-codage-ecrans-interface-realite-augmentee-logiciel-programmation-ingenieur-projet-developpeur-conception-application-illustration-dessin-anime_107791-3863.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="w-sceen py-5 md:px-5  px-3">
        <div className="container mx-auto w-full  bg-white px-6 my-4">
          {/* techno */}
          <div className="md:w-1/2 w-full px-2 py-1 mx-auto">
            <p className="md:my-2 my-3 text-2xl text-center  uppercase font-bold ">
              Technological Expertise
            </p>
            <p className="text-center my-2 text-sm">
              Proficient in a wide array of technologies, I specialize in both
              front-end and back-end development. My skills include
            </p>
          </div>
          <div className="grid gap-6 md:grid-cols-3 sm:grid-cols-2  relative mt-10 px-3">
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white  shadow hover:shadow-xl rounded-md px-4 py-3 "
            >
              <div className=" relative flex flex-row ju items-center">
                <img
                  className=" w-28 h-28 mx-auto "
                  src="https://img.icons8.com/clouds/100/react.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[#16163f] text-lg text-center">
                Reactjs & Vuejs & Angular
              </div>
              <p className="text-xs text-justify">
                Crafting visually appealing and user-friendly interfaces with
                the power of modern front-end frameworks.
              </p>
            </div>
            {/*  */}
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white  shadow hover:shadow-xl rounded-md px-4 py-3 "
            >
              <div className=" relative flex flex-row ju items-center">
                {/* <img  src="https://img.icons8.com/clouds/100/react.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/> */}
                <img
                  className=" w-28 h-28 mx-auto "
                  src="https://img.icons8.com/external-tal-revivo-green-tal-revivo/100/external-flutter-is-an-open-source-mobile-application-development-framework-created-by-google-logo-green-tal-revivo.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[#16163f] text-lg text-center">
                Flutter & React Native
              </div>
              <p className="text-xs text-justify">
                Developing cross-platform mobile applications that ensure a
                seamless experience on both iOS and Android platforms.
              </p>
            </div>
            {/*  */}
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white  shadow hover:shadow-xl rounded-md px-4 py-3 "
            >
              <div className=" relative flex flex-row ju items-center">
                <img
                  className=" w-28 h-28 mx-auto "
                  src="https://img.icons8.com/color/100/nextjs.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[#16163f] text-lg text-center">
                Next.js
              </div>
              <p className="text-xs text-justify">
                Leveraging the benefits of server-side rendering and efficient
                routing to create high-performance React applications
              </p>
            </div>
            {/*  */}
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white  shadow hover:shadow-xl rounded-md px-4 py-3 "
            >
              <div className=" relative flex flex-row ju items-center">
                <img
                  className=" w-28 h-28 mx-auto "
                  src="https://img.icons8.com/color/100/tailwind_css.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[rgb(22,22,63)] text-lg text-center">
                Tailwind CSS
              </div>
              <p className="text-xs text-justify">
                Utilizing the utility-first CSS framework to streamline the
                styling process and create responsive, modern, and maintainable
                designs.
              </p>
            </div>
            {/*  */}
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white  shadow hover:shadow-xl rounded-md px-4 py-3 "
            >
              <div className=" relative flex flex-row ju items-center">
                <img
                  className=" w-28 h-28 mx-auto "
                  src="https://img.icons8.com/external-tal-revivo-green-tal-revivo/72/external-django-a-high-level-python-web-framework-that-encourages-rapid-development-logo-green-tal-revivo.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[#16163f] text-lg text-center">
                Django and Laravel
              </div>
              <p className="text-xs text-justify">
                Creating powerful and scalable back-end solutions with these
                popular MVC frameworks.
              </p>
            </div>
            {/*  */}
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white  shadow hover:shadow-xl rounded-md px-4 py-3 "
            >
              <div className=" relative flex flex-row ju items-center">
                <img
                  className=" w-28 h-28 mx-auto "
                  src="https://img.icons8.com/dusk/100/wordpress--v1.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[#16163f] text-lg text-center">
                WordPress
              </div>
              <p className="text-xs text-justify">
                Designing and developing customized WordPress websites,
                combining flexibility and ease of use.
              </p>
            </div>
          </div>
          {/*  */}
          <div className="grid gap-2 md:grid-cols-2   relative  px-3  mt-10  md:border-0 border-2 border-dashed rounded">
            <div
              data-aos="fade-right"
              className="w-full px-2 py-1  md:border-dashed md:rounded md:border-2"
            >
              <img
                className=" w-28 h-28 mx-auto "
                src="https://img.icons8.com/bubbles/100/checked-checkbox.png"
                alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
              />
              <p className="text-justify my-2">
                This diverse set of skills allows me to approach projects with a
                comprehensive perspective, ensuring that both the front-end and
                back-end components are seamlessly integrated. Whether it's
                building a responsive website, developing a cross-platform
                mobile app, or creating a dynamic web application, I bring a
                versatile skill set to the table.
              </p>
            </div>
            <div
              data-aos="fade-left"
              className="w-full px-2 py-1 md:border-2 md:border-dashed md:rounded"
            >
              <img
                className=" w-28 h-28 mx-auto "
                src="https://img.icons8.com/clouds/100/search.png"
                alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
              />
              <p className="text-justify my-2">
                I am committed to staying abreast of the latest advancements in
                technology to provide my clients with innovative and up-to-date
                solutions. Let's explore how we can incorporate these
                technologies, including Tailwind CSS & Reactjs & Django, into
                your next digital venture!
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {/*  */}
      <div className="w-sceen py-5 md:px-5  px-3">
        <div className="container mx-auto w-full  bg-white px-6 my-4">
          <div className="grid gap-2 md:grid-cols-2 relative px-3 mt-10 ">
            <div data-aos="fade-right" className="w-full px-2 py-1 ">
              <p className="md:my-2 my-3 text-2xl text-center  uppercase font-bold ">
                Collaboration and Communication
              </p>
              <p className="text-justify my-2 text-sm">
                In the dynamic landscape of web and mobile development,
                effective collaboration and clear communication are integral to
                success. I prioritize fostering an environment of open dialogue
                and teamwork. Whether working with clients, designers, or fellow
                developers, I bring a collaborative mindset that ensures
                everyone's insights are valued. My goal is to translate ideas
                into actionable plans, fostering synergy to achieve project
                milestones seamlessly. Through transparent communication, I
                strive to keep all stakeholders informed and engaged, building a
                foundation for trust and successful project outcomes. This
                commitment to collaboration and communication not only enhances
                the development process but also contributes to the creation of
                exceptional digital experiences.
              </p>
            </div>

            <div data-aos="fade-left" className="w-full px-2 py-1 ">
              <p className="md:my-2 my-3 text-2xl text-center  uppercase font-bold ">
                Programming Problem Solving
              </p>
              <p className="text-justify my-2 text-sm">
                In the realm of software development, adept problem-solving
                skills form the cornerstone of my approach. I thrive on tackling
                complex coding challenges and finding elegant solutions. Whether
                it involves optimizing algorithms, debugging intricate issues,
                or architecting robust systems, my commitment to meticulous
                programming problem-solving ensures the delivery of efficient
                and effective solutions. This passion for overcoming coding
                obstacles fuels my dedication to creating streamlined and
                innovative software solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <hr />
      <div className="w-sceen py-5 md:px-5  px-3">
        <div className="container mx-auto w-full  bg-white px-6 my-4 ">
          <div className="flex md:flex-row flex-col w-full justify-center items-center">
            <div data-aos="fade-right" className="md:w-1/3 w-full my-2">
              <img
                src="https://img.freepik.com/vecteurs-libre/illustration-concept-cours_114360-1522.jpg?w=1800&t=st=1704276089~exp=1704276689~hmac=51bfe7345fde5efe6da1d0644e6bd592f9c7d40f909cf24c45db12884ef6b795"
                alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
              />
            </div>
            <div data-aos="fade-left" className="md:w-2/3 w-full my-2">
              <p className="text-justify my-1">
                In this dynamic field, I embrace the philosophy of continuous
                learning. Staying updated with the latest trends and
                technologies allows me to offer cutting-edge solutions and
                deliver projects that stand out in today's competitive digital
                landscape.
              </p>
              <p className="text-justify my-1">
                I am excited about the prospect of collaborating on innovative
                projects. Whether you're looking to revamp your web presence or
                create a standout mobile app, I'm here to turn your ideas into
                reality. Explore my portfolio, and let's start a conversation
                about how we can bring your digital vision to life!
              </p>
              <a href="/portfolio">
                <div className="my-3 border border-[#16163f] rounded bg-[#16163f] hover:bg-white text-white hover:text-[#16163f] font-bold px-5 py-2 cursor-pointer flex w-max">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                    />
                  </svg>
                  <p className="mx-2">My Portfolio</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <Footer />
    </div>
  );
}

export default AboutScreen;
