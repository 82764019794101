import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

import Aos from "aos";
import "aos/dist/aos.css";
import { listBlogs } from "../constants";

function BlogScreen() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className=" w-screen min-h-screen ">
      <Header />
      <div
        className=" w-screen bg-cover bg-white bg-opacity-20"
        style={{
          backgroundImage:
            'url("https://img.freepik.com/vecteurs-libre/fond-jaune-conception-lignes-demi-teintes_1017-30148.jpg")',
        }}
      >
        <div className="container mx-auto  w-full">
          <div className="flex flex-col  justify-center items-center mx-3 md:mt-0 mt-24">
            <div className="w-full flex flex-col justify-center items-center my-2">
              <div className="md:h-44 h-24"></div>
              <h2 className="text-green-600 md:text-6xl text-4xl font-bold my-2 drop-shadow-xl ">
                Blogs
              </h2>
              <h2 className="text-sm my-1 bg-[#fff7] rounded px-4 py-2 text-slate-500 ">
                Please have a look at some of our work.
              </h2>
              <div className="md:h-24 h-24"></div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className=" py-10">
        <div className="mx-auto container" id="portfolio">
          {/* <div className='w-full  flex justify-center items-center flex-col py-5 px-3 my-2'>
                <p className='text-center'>View some of my work and case studies for clients.</p>
            </div> */}
          <div className="grid gap-3 md:grid-cols-3 grid-cols-1 relative mb-10 px-3">
            {/* Control-Car - Acheter Occasion */}
            {listBlogs.map((blog, key) => (
              <>
                <a href={`/blog/${blog.ref}`}>
                  <div
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                    className="w-full shadow flex justify-center items-start flex-col  bg-white relative group rounded-md cursor-pointer overflow-hidden"
                  >
                    <img
                      alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                      src={blog.image}
                      className=" min-h-40 max-h-40 w-max  mx-auto"
                    />
                    <div className=" w-full group-hover:transition-all px-2 py-2">
                      <div className="text-sm text-black w-full font-bold my-2 overflow-hidden line-clamp-2 text-left">
                        {blog.title}
                      </div>
                      <div className="text-xs text-black w-full text-justify my-1 overflow-hidden line-clamp-2">
                        {blog.intro}
                      </div>
                      <div className="text-xs text-black w-full text-left my-1">
                        {blog.date} by {blog.by}
                      </div>
                    </div>
                  </div>
                </a>
              </>
            ))}

            {/*  */}
          </div>
        </div>
      </div>

      {/* footer */}
      <Footer />
    </div>
  );
}

export default BlogScreen;
