import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

import reparupWeb from '../assets/images/reparup-web.jpg'
import reparupClientApp from '../assets/images/reparup-client-app.jpg'
import reparupDeliveryApp from '../assets/images/reparup-delivery.jpg'
import quickyCleanWeb from '../assets/images/quicky-client-web.jpg'
import paytvisseWeb from '../assets/images/paytvisse.jpg'
import dobingoApp from '../assets/images/dobingo.jpg'
import Aos from 'aos'
import 'aos/dist/aos.css';

function PortfolioScreen() {
  useEffect(()=>{
    Aos.init();
  });

  return (
    <div className=' w-screen min-h-screen '>
        <Header/>
        <div className=' w-screen bg-cover bg-white bg-opacity-20' style={{backgroundImage:'url("https://img.freepik.com/vecteurs-libre/fond-jaune-conception-lignes-demi-teintes_1017-30148.jpg")'}}>
            <div className='container mx-auto  w-full'>
                <div className='flex flex-col  justify-center items-center mx-3 md:mt-0 mt-24'>
                <div className='w-full flex flex-col justify-center items-center my-2'>
                    <div className='md:h-44 h-24'></div>
                    <h2 className='text-green-600 md:text-6xl text-4xl font-bold my-2 drop-shadow-xl '>Portfolio</h2>
                    <h2 className='text-sm my-1 bg-[#fff7] rounded px-4 py-2 text-slate-500 '>Please have a look at some of our work.</h2>
                    <div className='md:h-24 h-24'></div>
                </div>
                </div>
            </div>
        </div>
        {/*  */}
        <div className=' py-10' >
          <div className='mx-auto container' id='portfolio'>
            <div className='w-full  flex justify-center items-center flex-col py-5 px-3 my-2'>
              <p className='text-center'>View some of my work and case studies for clients.</p>
            </div>
            <div className='grid gap-3 md:grid-cols-3 grid-cols-1 relative mb-10 px-3'>
              {/* Control-Car - Acheter Occasion */}
              <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='w-full flex justify-center items-start flex-col shadow hover:shadow-lg bg-white relative group rounded-md cursor-pointer overflow-hidden'>
                <img alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine' src='https://play-lh.googleusercontent.com/JJ2dQEbtRgcfCy0RF2ajn-nV_d6VVTV_ID10UiBpNXxbuHu4sOMKcfwkBVB5svFJLw4=w832-h470-rw' className=' min-h-40 min-w-full rounded-md '/>
                <div className=' absolute top-0 left-0 w-0 h-full group-hover:w-full group-hover:transition-all bg-gray-600 bg-opacity-80 overflow-hidden'>
                  <div className='text-sm text-white absolute top-2 w-full text-center font-bold' >Control-Car - Acheter Occasion</div>
                  <div className='text-sm text-white absolute bottom-2 w-full flex flex-wrap items-center justify-center' >
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Flutter</div>
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Django</div>
                  </div>
                  <div className='text-sm text-white absolute top-1/2 w-full text-center font-bold flex justify-center items-center' >
                    <a target='_blank' rel="noopener noreferrer" className=' cursor-pointer'  href='https://play.google.com/store/apps/details?id=com.ctrcar.client_control_car'>
                      <img className=' w-10 mx-2'  src="https://img.icons8.com/bubbles/100/android-os.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                    <a  target='_blank' rel="noopener noreferrer" className=' cursor-pointer' href='https://apps.apple.com/us/app/control-car-acheter-occasion/id6449749819'>
                      <img className=' w-10 mx-2' src="https://img.icons8.com/bubbles/100/mac-os.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                    <a  target='_blank' rel="noopener noreferrer" href='https://client.control-car.fr/' className=' cursor-pointer'>
                      <img className=' w-10 mx-2' src="https://img.icons8.com/bubbles/100/code-file--v1.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                  </div>
                </div>
              </div>
              {/* Control-Car - Tech & Garage */}
              <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='w-full flex justify-center items-start flex-col shadow hover:shadow-lg bg-white relative group rounded-md cursor-pointer overflow-hidden'>
                <img alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine' src='https://play-lh.googleusercontent.com/Zz4wvveYp3G-QbuJaRh42fSNhE1sVmpEbmmysLjWObpOs52d1BC3a7A8t1piAlapm3I=w832-h470-rw' className=' min-h-40 min-w-full rounded-md '/>
                <div className=' absolute top-0 left-0 w-0 h-full group-hover:w-full group-hover:transition-all bg-gray-600 bg-opacity-80 overflow-hidden'>
                  <div className='text-sm text-white absolute top-2 w-full text-center font-bold' >Control-Car - Tech & Garage</div>
                  <div className='text-sm text-white absolute bottom-2 w-full flex flex-wrap items-center justify-center' >
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Flutter</div>
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Django</div>
                  </div>
                  <div className='text-sm text-white absolute top-1/2 w-full text-center font-bold flex justify-center items-center' >
                    <a target='_blank' rel="noopener noreferrer"  className=' cursor-pointer' href='https://play.google.com/store/apps/details?id=com.ctrcar.client_pro_control_car'>
                      <img className=' w-10 mx-2'  src="https://img.icons8.com/bubbles/100/android-os.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                    <a  target='_blank' rel="noopener noreferrer" className=' cursor-pointer' href='https://apps.apple.com/us/app/control-car-tech-garage/id6449793222'>
                      <img className=' w-10 mx-2' src="https://img.icons8.com/bubbles/100/mac-os.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                    <a  target='_blank' rel="noopener noreferrer" href='https://pro.control-car.fr/' className=' cursor-pointer'>
                      <img className=' w-10 mx-2' src="https://img.icons8.com/bubbles/100/code-file--v1.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                  </div>
                </div>
              </div>
              {/* Reparup web */}
              <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='w-full flex justify-center items-start flex-col shadow hover:shadow-lg bg-white relative group rounded-md cursor-pointer overflow-hidden'>
                <img alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine' src={reparupWeb} className=' min-h-40 min-w-full rounded-md '/>
                <div className=' absolute top-0 left-0 w-0 h-full group-hover:w-full group-hover:transition-all bg-gray-600 bg-opacity-80 overflow-hidden'>
                  <div className='text-sm text-white absolute top-2 w-full text-center font-bold' >Repar'up - Web & Admin Project</div>
                  <div className='text-sm text-white absolute bottom-2 w-full flex flex-wrap items-center justify-center' >
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>ReactJs</div>
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Django</div>
                  </div>
                  <div className='text-sm text-white absolute top-1/2 w-full text-center font-bold flex justify-center items-center' >
                    <a target='_blank' rel="noopener noreferrer" href='http://15.188.52.23/' className=' cursor-pointer'>
                      <img className=' w-10 mx-2' src="https://img.icons8.com/bubbles/100/code-file--v1.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                  </div>
                </div>
              </div>
              {/* Reparup client App */}
              <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='w-full flex justify-center items-start flex-col shadow hover:shadow-lg bg-white relative group rounded-md cursor-pointer overflow-hidden'>
                <img alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine' src={reparupClientApp} className=' min-h-40 min-w-full rounded-md '/>
                <div className=' absolute top-0 left-0 w-0 h-full group-hover:w-full group-hover:transition-all bg-gray-600 bg-opacity-80 overflow-hidden'>
                  <div className='text-sm text-white absolute top-2 w-full text-center font-bold' >Repar'up - Client App Project</div>
                  <div className='text-sm text-white absolute bottom-2 w-full flex flex-wrap items-center justify-center' >
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Flutter</div>
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Django</div>
                  </div>
                  <div className='text-sm text-white absolute top-1/2 w-full text-center font-bold flex justify-center items-center' >
                    <a target='_blank' rel="noopener noreferrer"  className=' cursor-pointer' href='/#'>
                      <img className=' w-10 mx-2'  src="https://img.icons8.com/bubbles/100/android-os.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                    <a  target='_blank' rel="noopener noreferrer" className=' cursor-pointer' href='/#'>
                      <img className=' w-10 mx-2' src="https://img.icons8.com/bubbles/100/mac-os.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                    
                  </div>
                </div>
              </div>
              {/* Reparup delivery App */}
              <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='w-full flex justify-center items-start flex-col shadow hover:shadow-lg bg-white relative group rounded-md cursor-pointer overflow-hidden'>
                <img alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine' src={reparupDeliveryApp} className=' min-h-40 min-w-full rounded-md '/>
                <div className=' absolute top-0 left-0 w-0 h-full group-hover:w-full group-hover:transition-all bg-gray-600 bg-opacity-80 overflow-hidden'>
                  <div className='text-sm text-white absolute top-2 w-full text-center font-bold' >Repar'up - Delivery App Project</div>
                  <div className='text-sm text-white absolute bottom-2 w-full flex flex-wrap items-center justify-center' >
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Flutter</div>
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Django</div>
                  </div>
                  <div className='text-sm text-white absolute top-1/2 w-full text-center font-bold flex justify-center items-center' >
                    <a target='_blank' rel="noopener noreferrer"  className=' cursor-pointer' href='/#'>
                      <img className=' w-10 mx-2'  src="https://img.icons8.com/bubbles/100/android-os.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                    <a  target='_blank' rel="noopener noreferrer" className=' cursor-pointer' href='/#'>
                      <img className=' w-10 mx-2' src="https://img.icons8.com/bubbles/100/mac-os.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                    
                  </div>
                </div>
              </div>
              {/* Quicky Clean Web */}
              <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='w-full flex justify-center items-start flex-col shadow hover:shadow-lg bg-white relative group rounded-md cursor-pointer overflow-hidden'>
                <img alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine' src={quickyCleanWeb} className=' min-h-40 min-w-full rounded-md '/>
                <div className=' absolute top-0 left-0 w-0 h-full group-hover:w-full group-hover:transition-all bg-gray-600 bg-opacity-80 overflow-hidden'>
                  <div className='text-sm text-white absolute top-2 w-full text-center font-bold' >QuickyClean - Booking for Cleaning</div>
                  <div className='text-sm text-white absolute bottom-2 w-full flex flex-wrap items-center justify-center' >
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>PHP</div>
                  </div>
                  <div className='text-sm text-white absolute top-1/2 w-full text-center font-bold flex justify-center items-center' >
                    <a target='_blank' rel="noopener noreferrer" href='https://quickyclean.net/' className=' cursor-pointer'>
                      <img className=' w-10 mx-2' src="https://img.icons8.com/bubbles/100/code-file--v1.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                  </div>
                </div>
              </div>
              {/* paytvisse */}
              <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='w-full flex justify-center items-start flex-col shadow hover:shadow-lg bg-white relative group rounded-md cursor-pointer overflow-hidden'>
                <img alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine' src={paytvisseWeb} className=' min-h-40 min-w-full rounded-md '/>
                <div className=' absolute top-0 left-0 w-0 h-full group-hover:w-full group-hover:transition-all bg-gray-600 bg-opacity-80 overflow-hidden'>
                  <div className='text-sm text-white absolute top-2 w-full text-center font-bold' >Paytvisse - Arabic learning platform</div>
                  <div className='text-sm text-white absolute bottom-2 w-full flex flex-wrap items-center justify-center' >
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>WordPress</div>
                  </div>
                  <div className='text-sm text-white absolute top-1/2 w-full text-center font-bold flex justify-center items-center' >
                    <a target='_blank' rel="noopener noreferrer" href='https://paytvisse.com/' className=' cursor-pointer'>
                      <img className=' w-10 mx-2' src="https://img.icons8.com/bubbles/100/code-file--v1.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                  </div>
                </div>
              </div>
              {/* dobingo */}
              <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='w-full flex justify-center items-start flex-col shadow hover:shadow-lg bg-white relative group rounded-md cursor-pointer overflow-hidden'>
                <img alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine' src={dobingoApp} className=' min-h-40 min-w-full rounded-md '/>
                <div className=' absolute top-0 left-0 w-0 h-full group-hover:w-full group-hover:transition-all bg-gray-600 bg-opacity-80 overflow-hidden'>
                  <div className='text-sm text-white absolute top-2 w-full text-center font-bold' >Dobingo - Delivery App</div>
                  <div className='text-sm text-white absolute bottom-2 w-full flex flex-wrap items-center justify-center' >
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Flutter</div>
                    <div className='border rounded-md px-2 py-1 mx-1 bg-white bg-opacity-70 text-blue-600 text-sm font-bold'>Laravel</div>
                  </div>
                  <div className='text-sm text-white absolute top-1/2 w-full text-center font-bold flex justify-center items-center' >
                    <a target='_blank' rel="noopener noreferrer"  className=' cursor-pointer' href='https://play.google.com/store/apps/details?id=com.salah.services.dobingo&hl=fr&gl=US'>
                      <img className=' w-10 mx-2'  src="https://img.icons8.com/bubbles/100/android-os.png" alt='Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine'/>
                    </a>
                    
                  </div>
                </div>
              </div>
              
            </div>
            
          </div>
        </div>
        
        
        
        {/* footer */}
        <Footer/>

    </div>
  )
}

export default PortfolioScreen