import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Aos from "aos";
import "aos/dist/aos.css";

function ServiceScreen() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className=" w-screen min-h-screen ">
      <Header />
      <div
        className=" w-screen bg-cover bg-white bg-opacity-20"
        style={{
          backgroundImage:
            'url("https://img.freepik.com/vecteurs-libre/fond-jaune-conception-lignes-demi-teintes_1017-30148.jpg")',
        }}
      >
        <div className="container mx-auto  w-full">
          <div className="flex flex-col  justify-center items-center mx-3 md:mt-0 mt-24">
            <div className="w-full flex flex-col justify-center items-center my-2">
              <div className="md:h-44 h-24"></div>
              <h2 className="text-green-600 md:text-6xl text-4xl font-bold my-2 drop-shadow-xl">
                My Services
              </h2>
              <div className="md:h-24 h-24"></div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div id="services" className=" bg-no-repeat bg-cover py-10">
        <div className="mx-auto container">
          <div className="w-full  flex justify-center items-center flex-col py-5 px-3 md:w-2/3 mx-auto">
            <p className="my-3 text-center ">
              As a passionate web and mobile developer, I offer a range of
              services tailored to meet your digital needs. With a keen eye for
              detail and a commitment to delivering high-quality solutions, I
              specialize in crafting dynamic and responsive websites and mobile
              applications.
            </p>
          </div>
          <div className="grid gap-6 md:grid-cols-4 sm:grid-cols-2  relative mt-10 px-3  mx-auto">
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white  shadow md:hover:shadow-xl rounded-md px-4 py-3 "
            >
              <div>
                <img
                  className=" w-28 h-28 mx-auto"
                  src="https://img.icons8.com/clouds/100/web.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[#16163f] text-lg text-center">
                Web Development Services
              </div>
              <p className="text-sm text-justify">
                I bring your ideas to life by creating robust and interactive
                web applications. From front-end development using modern
                frameworks like React and Vue.js to back-end solutions with
                Node.js or Django, I ensure your website is not only visually
                appealing but also performs seamlessly across devices.
              </p>
            </div>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white shadow md:hover:shadow-xl rounded-md px-4 py-3"
            >
              <div>
                <img
                  className=" w-28 h-28 mx-auto"
                  src="https://img.icons8.com/clouds/100/multiple-smartphones.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[#16163f] text-lg text-center">
                Mobile App Development
              </div>
              <p className="text-sm text-justify">
                In the ever-evolving world of mobile technology, I design and
                develop custom mobile applications for both iOS and Android
                platforms. Using Flutter or React Native, I build cross-platform
                apps that provide a consistent user experience while optimizing
                development time and costs.
              </p>
            </div>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white shadow md:hover:shadow-xl rounded-md px-4 py-3"
            >
              <div>
                <img
                  className=" w-28 h-28 mx-auto"
                  src="https://img.icons8.com/clouds/100/figma.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[#16163f] text-lg text-center">
                Responsive Design
              </div>
              <p className="text-sm text-justify">
                I prioritize responsive design principles to guarantee your web
                and mobile applications look and function flawlessly on various
                screen sizes. Whether it's a sleek website or a user-friendly
                mobile app, I ensure a seamless and engaging experience for your
                audience.
              </p>
            </div>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="bg-white shadow md:hover:shadow-xl rounded-md px-4 py-3"
            >
              <div>
                <img
                  className=" w-28 h-28 mx-auto"
                  src="https://img.icons8.com/clouds/100/work.png"
                  alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                />
              </div>
              <div className="my-2 font-bold text-[#16163f] text-lg text-center">
                Maintenance and Support
              </div>
              <p className="text-sm text-justify">
                My commitment doesn't end with the launch of your project. I
                provide ongoing maintenance and support to ensure your web and
                mobile applications remain up-to-date, secure, and efficient. I
                am dedicated to keeping your digital presence running smoothly.
              </p>
            </div>
          </div>
          <div className="md:w-2/3 w-full  flex justify-center items-center flex-col py-5 px-3 my-2 mx-auto">
            <p className=" text-center">
              Let's collaborate to turn your vision into reality. Feel free to
              explore my portfolio and get in touch to discuss how I can
              contribute to your next digital venture!
            </p>
            <div className="flex">
              <a href="/about-us" className="mx-2">
                <div className="my-3 border border-[#16163f] rounded bg-[#16163f] hover:bg-white text-white hover:text-[#16163f] font-bold px-5 py-2 cursor-pointer flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                  <p className="mx-2">About Me</p>
                </div>
              </a>
              <a href="/portfolio" className="mx-2">
                <div className="my-3 border border-[#16163f] rounded bg-[#16163f] hover:bg-white text-white hover:text-[#16163f] font-bold px-5 py-2 cursor-pointer flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                    />
                  </svg>
                  <p className="mx-2">Portfolio</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <Footer />
    </div>
  );
}

export default ServiceScreen;
