import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

import Aos from "aos";
import "aos/dist/aos.css";
import { listBlogs } from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function ArticlBlogScreen() {
  let { ref } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let foundBlog;
    for (var i = 0; i < listBlogs.length; i++) {
      if (listBlogs[i].ref === ref) {
        console.log("Blog found");
        foundBlog = listBlogs[i];
        break; // No need to continue searching once the blog is found
      }
    }

    if (foundBlog) {
      setBlog(foundBlog);
      const script = document.createElement("script");

      // Set the type attribute to JSON-LD
      script.type = "application/ld+json";

      // Add the JSON-LD structured data as text content
      script.innerHTML = JSON.stringify(blog?.jsonld);

      // Append the script element to the document head
      document.head.appendChild(script);
      setIsLoading(false);

      // Clean up: remove the script element when the component is unmounted
      return () => {
        document.head.removeChild(script);
      };
    } else {
      // Handle the case where the blog is not found
      console.log("Blog not found");
      navigate("/");
    }

    setIsLoading(false);
    Aos.init();
  }, [ref, blog, navigate]);
  return (
    <div className=" w-screen min-h-screen ">
      <Header />
      {isLoading ? (
        <div className="h-screen w-screen fixed z-50 bg-[#0000005c] flex items-center justify-center scr">
          <svg
            aria-hidden="true"
            className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      ) : blog === undefined ? (
        <div>Oops</div>
      ) : (
        <>
          <Helmet>
            <title>{blog.seo.title}</title>

            {/* Additional meta tags */}
            <meta name="keywords" content={blog.seo.keywords} />
            <meta
              name="author"
              content="Labzioui Amine - Web and Mobile Apps Developer, Freelancer"
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />

            {/* Open Graph meta tags for better social media sharing */}
            <meta property="og:title" content={blog.seo.title} />
            <meta property="og:description" content={blog.seo.og_description} />
            <meta property="og:image" content={blog.image} />
            <meta
              property="og:url"
              content={"https://labz-ioui.com/blog/" + blog.ref}
            />
            <meta property="og:type" content="article" />

            {/* Twitter meta tags for better Twitter card presentation */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@LabziouiAmin" />
            <meta name="twitter:title" content={blog.seo.title} />
            <meta
              name="twitter:description"
              content={blog.seo.og_description}
            />
            <meta name="twitter:image" content={blog.image} />
          </Helmet>
          <div
            className=" w-screen bg-cover bg-white bg-opacity-20"
            style={{
              backgroundImage:
                'url("https://img.freepik.com/vecteurs-libre/fond-jaune-conception-lignes-demi-teintes_1017-30148.jpg")',
            }}
          >
            <div className="container mx-auto  w-full">
              <div className="flex flex-col  justify-center items-center mx-3 md:mt-0 mt-24">
                <div className="w-full flex flex-col justify-center items-center my-2">
                  <div className="md:h-44 h-24"></div>
                  <h2 className="text-green-600 md:text-6xl text-4xl font-bold my-2 drop-shadow-xl ">
                    Blog
                  </h2>
                  <h1 className="text-sm my-1 bg-[#fff7] rounded px-4 py-2 text-slate-500 ">
                    {blog.title}
                  </h1>
                  <div className="md:h-24 h-24"></div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className=" py-10">
            <div className="mx-auto px-4 container md:w-2/3 w-full">
              {/* image + intro */}
              <div className="flex md:flex-col flex-col ">
                <div className=" w-full px-2 py-2 mx-auto">
                  <img
                    alt="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
                    src={blog.image}
                    className="  mx-auto "
                  />
                </div>
                <div className="w-full px-2 text-justify">{blog.intro}</div>
              </div>
              <div className=" px-2 ">
                {blog.content.map((content, index) => (
                  <section key={index} className="mt-3">
                    {content.title && (
                      <h2 className="font-bold mb-1 text-lg">
                        {content.title}
                      </h2>
                    )}
                    <p className=" text-justify">{content.text}</p>
                  </section>
                ))}
              </div>
            </div>
          </div>

          {/* footer */}
          <Footer />
        </>
      )}
    </div>
  );
}

export default ArticlBlogScreen;
