
import './App.css';
import AboutScreen from './screens/AboutScreen';
import ContactScreen from './screens/ContactScreen';
import HomeScreen from './screens/HomeScreen';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import ServiceScreen from './screens/ServiceScreen';
import PortfolioScreen from './screens/PortfolioScreen';
import BlogScreen from './screens/BlogScreen';
import ArticlBlogScreen from './screens/ArticlBlogScreen';
import { HelmetProvider } from 'react-helmet-async';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/about-us",
    element: <AboutScreen />,
  },
  {
    path: "/services",
    element: <ServiceScreen />,
  },
  {
    path: "/portfolio",
    element: <PortfolioScreen />,
  },
  {
    path: "/contact-us",
    element: <ContactScreen />,
  },
  {
    path: "/blog",
    element: <BlogScreen />,
  },
  {
    path: "/blog/:ref",
    element: <ArticlBlogScreen />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
