import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

// import loading from '../assets/icons8-loading.gif'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import { baseURLmail } from '../constants';
import Aos from "aos";
import "aos/dist/aos.css";

function ContactScreen() {
  const [nameController, setNameController] = useState("");
  const [phoneController, setPhoneController] = useState("");
  const [emailController, setEmailController] = useState("");
  const [subjectController, setSubjectController] = useState("");
  const [messageController, setMessageController] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Aos.init();
    // useEffect(() => {
    //   const handleLoad = () => {
    //     // Set loading to false when the page has finished loading
    //     setIsLoading(false);
    //   };

    //   // Attach the event listener
    //   console.log("start load");
    //   window.addEventListener('load', handleLoad);

    //   // Cleanup the event listener when the component is unmounted
    //   return () => {
    //     console.log("end load");
    //     setTimeout(() => {

    //       window.removeEventListener('load', handleLoad);
    //     }, 3000);
    //   };
  });

  return (
    <div className=" w-screen min-h-screen relative ">
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
      />

      {isLoading && (
        <div className="h-screen w-screen fixed z-50 bg-[#0000005c] flex items-center justify-center scr">
          <svg
            aria-hidden="true"
            className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      )}
      <Header />
      <div
        className=" min-h-screen w-screen bg-cover bg-white bg-opacity-20 relative"
        style={{
          backgroundImage:
            'url("https://img.freepik.com/vecteurs-libre/fond-jaune-conception-lignes-demi-teintes_1017-30148.jpg")',
        }}
      >
        <div className="container mx-auto min-h-screen w-full">
          <div className="flex flex-col min-h-screen justify-center items-center mx-3 md:mt-0 mt-24">
            <div className="w-full flex flex-col justify-center items-start my-2">
              <div className="md:h-0 h-16"></div>
              <h1 className="text-[#16163f] text-sm font-bold my-1">
                Labzioui Amine - Web & Mobile Apps Developer.
              </h1>
              <h2 className="text-green-600 md:text-6xl text-4xl font-bold my-2 ">
                Get in touch with me.
              </h2>
              <h2 className=" md:text-base text-sm my-1">
                Please feel free to contact us with any questions. We will be
                glad to hear from you!
              </h2>
              <div className="md:h-2 h-20"></div>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1 grap-3 w-full md:absolute md:bottom-2 container">
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                className="shadow-md  hover:shadow-lg bg-white px-6 py-6 mx-3 mb-3 rounded-xl"
              >
                <div className=" bg-[#9dd6a526] w-max rounded-full p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-10 h-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0 6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
                    />
                  </svg>
                </div>
                <p className="my-3 font-bold ">Give me a call</p>
                <p>+2126 55 80 67 57</p>
              </div>
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                className="shadow-md  hover:shadow-lg bg-white px-6 py-6 mx-3 mb-3 rounded-xl"
              >
                <div className=" bg-[#9dd6a526] w-max rounded-full p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-10 h-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
                    />
                  </svg>
                </div>
                <p className="my-3 font-bold ">Send me an email</p>
                <p>contact@labz-ioui.com</p>
              </div>
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                className="shadow-md hover:shadow-lg bg-white px-6 py-6 mx-3 mb-3 rounded-xl"
              >
                <div className=" bg-[#9dd6a526] w-max rounded-full p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-10 h-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                    />
                  </svg>
                </div>
                <p className="my-3 font-bold ">Visit Me</p>
                <p>Marrakech Morocco & I online on all world</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto px-3">
        <p className="my-6 font-bold  md:text-left text-center ">
          Send me a message or give a call
        </p>
        <div className="font-bold md:text-6xl text-2xl text-green-600 mb-16 md:text-left text-center">
          Contact me today to start building your next project together.
        </div>
        <div className="flex md:flex-row flex-col ">
          <div
            data-aos="fade-right"
            className="md:w-1/2 w-full  border-dashed border-2  p-2 rounded"
          >
            <div className="flex md:flex-row flex-col  my-1">
              <input
                value={nameController}
                onChange={(value) => setNameController(value.target.value)}
                className="md:w-1/2 w-full border rounded md:mx-1 mx-auto my-1  px-2 py-2"
                placeholder="Enter your name"
              />
              <input
                value={phoneController}
                onChange={(value) => setPhoneController(value.target.value)}
                className="md:w-1/2 w-full border rounded md:mx-1 mx-auto my-1 px-2 py-2"
                placeholder="Enter your phone number"
              />
            </div>
            <div className="flex md:flex-row flex-col  my-1">
              <input
                value={emailController}
                onChange={(value) => setEmailController(value.target.value)}
                className="w-full border rounded md:mx-1 mx-auto px-2 my-1 py-2"
                placeholder="Your email"
              />
            </div>
            <div className="flex md:flex-row flex-col   my-1">
              <input
                value={subjectController}
                onChange={(value) => setSubjectController(value.target.value)}
                className=" w-full border rounded md:mx-1 mx-auto my-1 px-2 py-2"
                placeholder="Subject"
              />
            </div>
            <div className="flex md:flex-row flex-col   my-1">
              <textarea
                rows={5}
                value={messageController}
                onChange={(value) => setMessageController(value.target.value)}
                className="w-full border rounded my-1 md:mx-1 mx-auto px-2 py-2"
                placeholder="Your Message"
              ></textarea>
            </div>
            <div
              onClick={async () => {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const isValid = emailPattern.test(emailController);
                if (emailController === "") {
                  toast.error("Please enter your email");
                }
                if (phoneController === "") {
                  toast.error("Please enter your phone number");
                } else if (subjectController === "") {
                  toast.error("Please enter your subject");
                } else if (messageController === "") {
                  toast.error("Please enter your message");
                } else if (!isValid) {
                  toast.error("Please enter your correct email");
                } else {
                  // toast.success("Email sent successfully!")
                  setIsLoading(true);
                  try {
                    await axios.post(
                      "https://mail-api.amanayhome.com/send-email",
                      {
                        name: nameController,
                        email: emailController,
                        phone: phoneController,
                        subject: subjectController,
                        message: messageController,
                      },
                      {
                        withCredentials: true, // Ensure CORS is enabled
                      }
                    );
                    setIsLoading(false);
                    setEmailController("");
                    setNameController("");
                    setPhoneController("");
                    setSubjectController("");
                    setMessageController("");
                    toast.success("Email sent successfully!");
                    // alert('Email sent successfully!');
                  } catch (error) {
                    setIsLoading(false);
                    toast.error("Error sending email. Please try again.l");
                    console.error("Error sending email:", error.message);
                    // alert('Error sending email. Please try again.');
                  }
                }
              }}
              className="my-3 border border-[#16163f] rounded bg-[#16163f] hover:bg-white text-white hover:text-[#16163f] font-bold px-5 py-2 cursor-pointer flex w-max mx-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                />
              </svg>
              <p className="mx-2">Send Message</p>
            </div>
          </div>
          <div data-aos="fade-left" className="md:w-1/2 w-full items-center ">
            <iframe
              title="Web, Mobile apps developer &amp; digital marketing - Freelancer | Labzioui Amine"
              className="w-full "
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13587.57737222575!2d-8.015811871179192!3d31.636743146761695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2sma!4v1703860411401!5m2!1sfr!2sma"
              height="450"
              style={{ border: "0" }}
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      {/* footer */}
      <Footer />
    </div>
  );
}

export default ContactScreen;
